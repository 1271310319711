.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;

    h2 {
        color: var(--secondary-color);
        font-weight: 800;
        left: 0;
    }

    p {
        
        color: var(--text-color);
        font-size: 16px;
        max-width: 1200px;
        padding: 15px;
        line-height: 2;
        text-indent: 60px;
        backdrop-filter: blur(10px);
    }
    
}



.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.app__profile-item {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
    
    h2 {
        color: var(--secondary-color);
    }
    
    img {
        width: 100%;
        height: 170px;
        border-radius: 15px;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px){
        width: 370px;
        margin: 2rem 4rem;

        img {           
            height: 320px;          
        }
        
    }
}