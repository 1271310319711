
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


:root {
  --font-base: "Rajdhani", sans-serif;

  --primary-color: #141414;
  --secondary-color: #ac848e;
  --text-color: #f0f0f0;
  --lightGray-color: #4b4b4b;
  --gray-color: #717171;
  --brown-color: #46364a;
  
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}