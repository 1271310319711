#home {
    position: relative;    
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }

    p {
        color: var(--text-color);
        font-size: 14pt;; 
        padding: 5px;
    }

    h2 {
        padding-top: 2rem;
        padding-bottom: 1rem;
        font-weight: 600;
        font-size: 26pt;
        color: var(--text-color);

        @media screen and (max-width: 1200px) {
            font-size: 18pt;
            
        }

    
    }

   
}



.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 400px) {
        padding: 6rem 1rem 2rem;
    }

    h1 {
        font-size: 56px;    
        color: var(--text-color);
        @media screen and (max-width: 1200px) {
            font-size: 36px;
        }        
    }
}



.box {
    width: 200px;
    height: 200px;
    background: rgba(210,210,210,1);

    @media screen and (max-width: 1200px ){
        width: 90px;
        height: 90px;
    }
}

.app__header-info {
    flex: 1;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
    }

    
}

.app__header-badge {
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    padding-bottom: 1rem;
    padding-top: 1rem;
    
    .badge-cmp, .tag-cmp {
        padding: 1rem 1rem;
        border: var(--text-color);
        
        border-radius: 15px;
        flex-direction: column;
        width: auto;

    }

    @media screen and (max-width: 1200px){
        justify-content: center;
        align-items: center;

        h1, p {
            text-align: center;
            margin-left: 20px;
            margin-right: 20px;
        }

       
        
    }

    

}


.app__header-img {
    flex: 1;
    height: 100%;

    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
        width: 100%;
        object-fit: contain;
        z-index: 1;
    }

    .overlay_circle {
        position: absolute;
        
        width: 750px;
        height: 750px;

        @media screen and (max-width: 1200px ){
            width: 450px;
            height: 450px;
        }
        
    }
}