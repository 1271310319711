.app__cases {
    flex: 1;
    width: 100%;
    flex-direction: column;

    h2 {
        color: var(--text-color);
        font-weight: 800;
    }
    
}

.statement {
    color: var(--text-color);
    font-weight: 600;
    font-style: italic;
    font-size: 20px;
    padding-top: 4rem;
    text-align: center;
}
.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    
}

.case-item {
    width: 380px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    
    
    h2 {
        color: var(--secondary-color);
    }

    p {
        color: var(--text-color);
        font-size: 16px;
        
    }
    
    img {
        width: 100%;
        height: 170px;
        border-radius: 15px;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px){
        width: 370px;
        margin: 2rem 4rem;

        img {           
            height: 320px;          
        }
        
    }
}