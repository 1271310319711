.app__navbar {
    width: 100%;
    height: 60px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;    
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    position: fixed;
    z-index: 20;
}

.lang-btn {
    background-color: transparent;
    padding: 8px 8px;
    border: 2px solid #6f6f6f;
    border-radius: 20px;
    font-size: 14px;
    color: #e0e0e0;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 900px) {
        font-size: 0;
        padding-left:2px;
        padding-right: 2px;
        border-radius: 50px;
        
    }

    &:hover {
        background-color: #e0e0e0;
        color: #3d3d3d;
    }

    img {
        width: 20px;
        margin-right: 2px;
        padding-left: 2px;
        padding-right: 0;
    }
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    

    img {
        width: 240px;
        padding-top: 60px;           

        @media screen and (min-width: 2000px) {
            width: 360px; 
            padding-top: 100px;           
        }

        @media screen and (max-width: 900px) {
            width: 150px;
            padding-top: 10px;
            margin-right: 10px;
            
        }
    }
}

.app__navbar-links {
    flex: 1;
    height: 100%;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    
    
    li {
        margin: 0 1rem;
        cursor: pointer;

        flex-direction: column;

        div {
            width: 7px;
            height: 7px;
            background: transparent;
            border-radius: 50%;
            margin-bottom: 5px;
        }

        a {
            color: var(--text-color);
            text-decoration: none;
            flex-direction: column;
            text-transform: uppercase;            
            transition: all 0.2s ease-in-out;
            font-size: 10pt;
            &:hover {
                color: var(--secondary-color);
            }
        }

        &:hover {
            div {
                background: var(--secondary-color);
            }
        }
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;

    display:flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);

    svg {
        width: 70%;
        height: 70%;
        color: #e0e0e0;
    }

    div {
        position: fixed;
        top: 0;
        bottom: 0px;
        right: 0px;
        z-index: 5;
        padding: 1rem;
        width: 80%;
        height: 100vh;
        
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;        
        background-color: var(--primary-color);
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

        svg {
            width: 35px;
            height: 35px;
            color: var(--secondary-color);
            margin: 0.5rem 1rem;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display:flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li {
                margin: 1rem;

                a {
                    color: var(--text-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 700;

                    transition: all 0.2s ease-in-out;
            
                    &:hover {
                        color: var(--secondary-color);
                    }
                }
            }
        }

        @media screen and (min-width: 900px) {
            display: none;
        }
    }

    @media screen and (min-width: 900px) {
        display: none;
    }
}
