.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;

    h2 {
        color: var(--secondary-color);
        backdrop-filter: blur(10px);
    }

    h3 {
        color: var(--text-color);
    }
}



.app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 1rem 1rem;

    .wechatqr {
        max-width: 290px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 1rem 0;
        padding: 0.5rem;
        border-radius: 10px;
        cursor: pointer;
        background-color: #d2d2d2;
        transition: all 0.3s ease-in-out;

        img {
            max-width: 120px;
        }

    }

    .app__footer-card {
        min-width: 290px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin: 1rem 0;
        padding: 1rem;
        border-radius: 10px;
        cursor: pointer;
        background-color: #d2d2d2;

        transition: all 0.3s ease-in-out;

        img {
            width: 40px;
            height: 40px;
            margin: 0 0.7rem;
        }

        p {
            font-weight: 600;         

        }

        a {
            text-decoration: none;
            font-weight: 700;
            font-size: 15px;
        }

        &:hover {
            box-shadow: 0 0 15px #fef4f5;
        }

        @media screen and (max-width: 450px) {
            width: 100%;
                       
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
                   
    }
}

.app__footer-cards .app__footer-card:last-child {
    background-color: #d2d2d2;
}

.app__footer-form {
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;

    div {
        width: 100%;
        margin: 0.75rem 0;
        border-radius: 10px;
        cursor: pointer;
        background-color: var(--primary-color);

        transition: all 0.3s ease-in-out;

        input, textarea {
            width: 100%;
            padding: 0.95rem;
            border: none;
            border-radius: 7px;
            background-color: rgba(56, 56, 56, 0.3);
            font-size: 16px;
            font-family: var(--font-base);
            color: var(--secondary-color);
            outline: none;
        }

        textarea {
            height: 170px;
        }

        &:hover {
            box-shadow: 0 0 15px #515151;
        }
    }

    button {
        padding: 1rem 1rem;
        border-radius: 10px;
        border: none;
        background-color: var(--secondary-color);
        font-size: 20px;
        font-weight: 700;
        color: black;
        outline: none;
        margin: 2rem 0 0 0;
        font-family: var(--font-base);
        cursor: pointer;

        &:hover {
            transition: all 0.3s ease-in-out;
            box-shadow: 0 0 15px #515151;
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 1rem 0;           
    }
}