.title {
    h2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 2rem;
    
    }

}

.app__skills-container {
    backdrop-filter: blur(10px);
}
.app__skills-exp-item {
    width: 1005;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
    
}

.app__skills-exp-works {
    flex: 1.75;
    
    .app__skills-exp-work {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        cursor: pointer;

        h4 {
            font-weight: 700;
            color: var(--text-color);
        }

        p {
            font-weight: 400;
            font-size: 16px;
            color: var(--text-color);
            margin-top: 5px;

        }
    }
}

.app__skills-exp-year {
    margin-right: 3rem;
    flex: 0.25;
    p {
        font-weight: 800;
        color: var(--secondary-color);
        font-size: 20px;
    }

    @media screen and (max-width: 450px) {
        margin-right: 1rem;
        
    }
}

.skills-tooltip {
    max-width: 300px;
}